<template>
  <div>
    <div class="row align-items-center">
      <div class="col-md-8">
        <h1 class="heading-txt mb-3">Melior plans</h1>
      </div>
      <div class="col-md-4">
        <div class="text-right">
          <div class="period-toggle">
            <label for="" @click="isMonthly = false">Annually</label>
            <vs-switch v-model="isMonthly" />

            <label for="" @click="isMonthly = true">Monthly</label>
          </div>
        </div>
      </div>
    </div>
    <SectionLoading v-if="loading" text="Melior plans loading..." />
    <div class="row">
      <div v-for="(plan, index) in plans" :key="index" class="col-md-4" v-if="plan.status == 'active'">
        <div class="card siteCard mt-3">
          <div class="p-3">
            <div class="row">
              <div class="col-7">
                <p class="active-plan">{{ plan.for }}</p>
              </div>
              <div class="col-5 text-right">
                <vs-chip> {{ plan.name }} </vs-chip>
              </div>
            </div>
            <h2 v-if="isMonthly" class="text-dark font-weight-bold">
              {{ plan.amount_monthly | currency("₦", 0) }} /month
            </h2>
            <h2 v-else class="text-dark font-weight-bold">
              {{ plan.amount_yearly | currency("₦", 0) }} /year
            </h2>
            <p class="text-muted text-sm">
              {{ plan.description }}
            </p>

            <button @click="initiateSubscription(plan)" class="btn btn-primary btn-site px-5 mt-3 w-full"
              :disabled="plan.amount_yearly == 0 && plan.amount_monthly == 0">
              <span> Subscribe</span>
            </button>

            <div class="plans-feat">
              <ul class="pl-2">
                <li v-for="(perk, index) in plan.perks" :key="index">
                  <p>{{ perk }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>



    <!-- Initiate Subscripiton -->
    <vs-popup title="Upgrade Plan" :active.sync="initiateUpgradeModal">
      <form @submit.prevent="onSubscriptionSubmit()">
        <div class="mb-4 border-b border-gray-100/30 pb-4 space-y-2 flex flex-col">
          <div class="space-y-2 bg-blue-50 py-2 px-3 rounded-md">
            <div class="flex items-center justify-between">
              <div class="flex items-center space-x-3">
                <div>
                  <p class="text-sm mb-1 font-bold">Melior Start</p>

                  <p class="text-xs mb-0 text-gray-500"> {{ initiateData.value }} {{ isMonthly ? 'months' : 'years' }}
                  </p>
                </div>
              </div>

              <span class="text-xl font-bold">
                {{ planAmount | currency("₦", 0) }}
              </span>
            </div>
          </div>
        </div>

        <div class="mb-4">
          <label class="text-sm block text-gray-700 font-medium mb-1">Select {{ isMonthly ? 'Months' : 'Years' }}</label>

          <vs-select v-if="isMonthly" required class="s-select mb-2" v-model="initiateData.value" >
            <vs-select-item :value="3" text="3 months" />
            <vs-select-item :value="6" text="6 months" />
            <vs-select-item :value="12" text="12 months" />
          </vs-select>

          <vs-select v-else required class="s-select mb-2" v-model="initiateData.value" >
            <vs-select-item :value="1" text="1 year" />
            <vs-select-item :value="2" text="2 years" />
            <vs-select-item :value="3" text="3 years" />
          </vs-select>

          <!-- <div class="px-2 mt-2">
            <vs-slider :min="initiateData.min" :max="initiateData.max" v-model="initiateData.value" />
          </div> -->
        </div>

        <button type="submit"
          class="bg-primary mt-4 w-full text-white py-3 px-4 hover:bg-primary rounded hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
          Complete Upgrade
        </button>
      </form>
    </vs-popup>
  </div>
</template>
<script>
import PaystackPop from "@paystack/inline-js";
import SectionLoading from "@/components/SectionLoading";

export default {
  data() {
    return {
      plans: [],
      loading: false,
      isMonthly: true,
      sub_id: "",
      subscriptionData: {
        email: "",
        amount: "",
      },
      initiateUpgradeModal: false,
      initiateData: {
        value: 3,
        min: 3,
        max: 20,
        plan: {}
      }
    };
  },
  components: { SectionLoading },
  mounted() {
    this.getPlans();
  },
  methods: {
    verifyPayment(response) {
      this.$vs.loading();
      const data = {
        reference: response.reference,
        sub_id: this.sub_id,
      };
      let fetch = {
        path: `subscription/verify-payment`,
        data,
      };
      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          this.$vs.loading.close();
          this.initiateUpgradeModal = false;
          this.$toast.success(
            "Subscription Initialized",
            "Proceeding to payment gateway",
            this.$toastPosition
          );
          this.$router.push("/subscription-complete");
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Subscription Upgrade",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Subscription Upgrade",
              "Failed to verify payment, Please contact support.",
              this.$toastPosition
            );
          }
        });
    },
    generateReference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
    initializePaystack() {
      const paystack = new PaystackPop();

      paystack.newTransaction({
        // pk_test_7e2135ee91fbdc3d3d3117445c175223ea760df2
        key: "pk_live_4d52d98118904161226a6e27f53d3a7161d7db86",
        email: this.user.email,
        amount: this.subscriptionData.amount * 100,
        reference: this.generateReference(),
        callback: (response) => {
          this.verifyPayment(response);
        },
        onClose: () => {
          this.$vs.loading.close();
          this.$toast.info(
            "Payment was cancelled, please try again later.",
            "Subscription Upgrade",
            this.$toastPosition
          );
        },
      });
    },
    getUUid(urlString) {
      const url = new URL(urlString);
      const uuid = url.search.replace("?uuid=", "");
      return uuid || null;
    },
    subscriptionPlan(plan) {
      const data = {
        plan_id: plan.id,
        type: this.isMonthly ? "monthly" : "yearly",
        period: this.isMonthly ? this.initiateData.value : this.initiateData.value * 12,
      };

      this.subscriptionData.amount = this.planAmount
      this.$vs.loading();
      let fetch = {
        path: `subscription/begin-upgrade`,
        data,
      };
      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          this.$vs.loading.close();
          this.$toast.success(
            "Subscription Initialized",
            "Proceeding to payment gateway",
            this.$toastPosition
          );
          const { data } = resp;
          const uuid = this.getUUid(data.data.url);
          this.sub_id = uuid;
          this.initializePaystack();
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Subscription Upgrade",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Subscription Upgrade",
              "Something went wrong, please try again",
              this.$toastPosition
            );
          }
        });
    },
    onSubscriptionSubmit() {
      this.subscriptionPlan(this.initiateData.plan)
    },
    initiateSubscription(plan) {
      if (this.isMonthly) {
        this.initiateData.min = 3
        this.initiateData.max = 20
        this.initiateData.value = 3
      } else {
        this.initiateData.min = 1
        this.initiateData.max = 5
        this.initiateData.value = 1
      }
      this.initiateData.plan = plan;
      this.initiateUpgradeModal = true;
    },
    getPlans() {
      let fetch = {
        path: `/admin/subscription`,
      };
      this.loading = true;
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          const { data } = resp.data;
          this.plans = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Error",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "All Plans",
              text: "Unable to get Plans",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
  },
  computed: {
    user() {
      return this.$store.getters.user || {};
    },
    planAmount() {
      if (this.isMonthly) {
        return this.initiateData.plan.amount_monthly * this.initiateData.value
      } else {
        return this.initiateData.plan.amount_yearly * this.initiateData.value
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.sect-banner {
  background: #240f0c;
  padding: 28px;
  position: relative;
  overflow: hidden;

  .img-preview {
    width: 20em;
    position: absolute;
    right: 89px;
    top: 0%;
  }
}

.heading-txt {
  font-size: 3em;
  line-height: 1;
  font-weight: 700;
  color: #000;
}

.sect-data {
  border-bottom: 1px solid gainsboro;
  margin-bottom: 13px;
}

.active-plan {
  color: #000;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 2px;
}

.plans-feat {
  margin-top: 12px;

  li {
    color: black;

    p {
      font-size: 14px;
      margin-bottom: 4px;
    }
  }
}

.period-toggle {
  align-items: center;
  display: flex;
  margin: 0 auto;
  float: right;

  label {
    padding: 0px 9px;
    color: #000;
    margin-bottom: 0px;
    cursor: pointer;
  }
}
</style>
